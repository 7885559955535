
export enum CorrectionActionTypeCd {
	APPROVED = 'APPROVED',
	AUTO_APPROVED = 'AUTO_APPROVED',
	EXIT = 'EXIT',
	HOLD = 'HOLD',
	MORE_INFO = 'MORE_INFO',
	NOT_APPROVED = 'NOT_APPROVED',
	REASSIGN = 'REASSIGN',
	UNKNOWN = 'UNKNOWN',
	WIP = 'WIP'}
